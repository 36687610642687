const backend = `https://sequencegameapi.tedoratech.com/sequenceApi`
// const backend = `http://localhost:8000/sequenceApi`

const skipErrorFor = [''];

export const HTTPRequest = (method, url, body, succesHandler, errorHandler, type) => {
    return (dispatch, getState) => {
        var requestOptions = {
            method: method,
            headers: {
                "Authorization": localStorage.getItem("accessToken")
            }
        }
    
        if((method === 'POST' || method === 'PUT') && type !== 'form'){
            requestOptions['body'] = JSON.stringify(body);
            requestOptions.headers["Content-Type"] = "application/json";
        } else if(method === 'POST' || method === 'PUT') {
            requestOptions['body'] = body;
        }
    
        fetch(String(url).startsWith('https') ? url : `${backend}${url}`, requestOptions).then((response)=> {
            if(response.status !== 404){
                response.json().then(data => {
                    if(response.ok){
                        if(typeof(succesHandler) === 'function'){
                            succesHandler(data)
                        }
                    } else {
                        if(response.status === 401 && !String(url).includes("user")){
                            localStorage.removeItem("accessToken");
                            window.location.reload();
                            return;
                        }
                        if(typeof(errorHandler) === 'function'){
                            errorHandler(data)
                        } else {
                            if(skipErrorFor.indexOf(url) === -1){
                                // dispatch(setType('error'));
                                // dispatch(setBody(data.message));
                            }
                        }
                    }
                })   
            } else {
                errorHandler({message: 'Internal server error'})
            }
        }).catch(error => {
            if(typeof(errorHandler) === 'function'){
                errorHandler(error)
            }
        })
    }
    
}