import { createSlice } from "@reduxjs/toolkit";
import { HTTPRequest } from "../Api";

const GameSlice = createSlice({
    name: 'game',
    initialState: {
        currentUser: '',
        game: {},
        messages: [],
        lastSeenMessageTime: 0,
        badgeCount: 0,
        analyticsInstance: null
    },
    reducers: {
        setCurrentUser: (state, action) => {
            state.currentUser = action.payload;
        },
        setGame: (state, action) => {
            state.game = action.payload;
        },
        setMessages: (state, action) => {
            state.messages = action.payload;
        },
        setLastSeenMessageTime: (state, action) => {
            state.lastSeenMessageTime = action.payload;
        },
        setBadgeCount: (state, action) => {
            state.badgeCount = action.payload;
        },
        setAnalyticsInstance: (state, action) => {
            state.analyticsInstance = action.payload;
        }
    }
})

export function trackGame(id, userId, completed){
    return (dispatch) => {
        dispatch(HTTPRequest('GET', 'https://api-bdc.net/data/ip-geolocation?key=bdc_8ecf50a6a22c41feb798edcb472a40ff', null, (data) => {
            dispatch(HTTPRequest('POST', `/session`, {
                userId: userId,
                gameId: id,
                requestIp: data.ip,
                country: data.country.name,
                countryCode: data.country.isoAlpha2,
                state: data.location.principalSubdivision,
                city: data.location.city,
                latitude: data.latitude,
                longitude: data.longitude
            }, (response) => {
                if(typeof(completed) === 'function'){
                    completed();
                }
            }))
        }))
    }
}

export function createGame(data, completed){
    return (dispatch) => {
        dispatch(HTTPRequest('POST', '/game', data, (result) => {
            dispatch(setGame(result));
            if(typeof(completed) === 'function'){
                completed(result);
            }
        }))
    }
}

export function getGame(id, userId, completed){
    return (dispatch) => {
        dispatch(HTTPRequest('GET', `/game/${id}?userId=${userId}`, {}, (result) => {
            dispatch(setGame(result));
            if(typeof(completed) === 'function'){
                completed(result);
            }
        }))
    }
}

export function joinGame(id, data, completed){
    return (dispatch) => {
        dispatch(HTTPRequest('POST', `/game/join/${id}`, data, (result) => {
            dispatch(getGame(id));
            if(typeof(completed) === 'function'){
                completed();
            }
        }))
    }
}

export function checkAndJoinGame(data, completed){
    return (dispatch) => {
        dispatch(HTTPRequest('POST', `/game/checkandjoin`, data, (result) => {
            if(typeof(completed) === 'function'){
                completed(result);
            }
        }))
    }
}

export function setPlayers(id, players, completed){
    return (dispatch) => {
        dispatch(HTTPRequest('POST', `/game/${id}`, players, (response) => {
            dispatch(getGame(id));
            if(typeof(completed) === 'function'){
                completed();
            }
        }))
    }
}

export function startGame(id, completed){
    return (dispatch) => {
        dispatch(HTTPRequest('POST', `/game/start/${id}`, {}, (response) => {
            dispatch(getGame(id));
            if(typeof(completed) === 'function'){
                completed();
            }
        }))
    }
}

export function makeMove(id, location, userId, card, game, completed){
    return (dispatch, getState) => {
        let body = {
            pos: location,
            userId: userId,
            card: card,
            team: String(game.players.find(user => user.userId === userId).team).toLowerCase()
        }
        dispatch(HTTPRequest('POST', `/game/move/${id}`, body));
    }
}

export function sendFeedback(body, completed){
    return (dispatch) => {
        dispatch(HTTPRequest('POST', `/feedback`, body, (response) => {
            if(typeof(completed) === 'function'){
                completed();
            }
        }))
    }
}

export function getMessages(id, completed){
    return (dispatch, getState) => {
        let query = '';
        if(getState().game.messages.length > 0){
            let lastMessageTime = new Date(getState().game.messages[getState().game.messages.length - 1].createdAt).getTime();
            query = `?lastMessageTime=${lastMessageTime}`;
        }
        dispatch(HTTPRequest('GET', `/messages/${id}${query}`, {}, (response) => {
            let currentMessages = getState().game.messages;
            let lastSeenMessageTime = getState().game.lastSeenMessageTime;
            let messagesNotSeen = currentMessages.filter(message => new Date(message.createdAt).getTime() > lastSeenMessageTime).length;
            dispatch(setBadgeCount(messagesNotSeen));
            if(Array.isArray(response) && response.length > 0){
                dispatch(setMessages([...currentMessages, ...response]));
            }
            if(typeof(completed) === 'function'){
                completed(response);
            }
        }))
    }
}

export function sendMessage(body, completed){
    return (dispatch) => {
        dispatch(HTTPRequest('POST', `/messages`, body, (response) => {
            if(typeof(completed) === 'function'){
                completed(response);
            }
        }))
    }
}

export const {setGame, setMessages, setCurrentUser, 
                setLastSeenMessageTime, setBadgeCount, setAnalyticsInstance } = GameSlice.actions;

export const currentUserSelector = (state) => state.game.currentUser;
export const gameSelector = (state) => state.game.game;
export const messagesSelector = (state) => state.game.messages;
export const lastSeenMessageTimeSelector = (state) => state.game.lastSeenMessageTime;
export const badgeCountSelector = (state) => state.game.badgeCount;
export const analyticsInstanceSelector = (state) => state.game.analyticsInstance;

export default GameSlice.reducer;