import './App.css';
import { Box, Grid, Typography } from '@mui/material';
import Board from './Components/Board';
import { Route, Routes } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Notifications from './Notifications';
import Chat from './Components/Chat';
import { useDispatch } from 'react-redux';
import { initializeApp } from 'firebase/app';
import { getAnalytics } from "firebase/analytics";
import { setAnalyticsInstance } from './services/GameSlice';

function App() {
  const dispatch = useDispatch();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);

  const firebaseConfig = {
    apiKey: "AIzaSyDxbgKv9nThMpKYIzv9U7R-JQAXFAUAftA",
    authDomain: "play-sequence.firebaseapp.com",
    projectId: "play-sequence",
    storageBucket: "play-sequence.appspot.com",
    messagingSenderId: "154367757387",
    appId: "1:154367757387:web:185111808b8235ec9091ce",
    measurementId: "G-0JSK5D2DNH"
  };

  useEffect(() => {
    const app = initializeApp(firebaseConfig);
    const analytics = getAnalytics(app);
    dispatch(setAnalyticsInstance(analytics));
    // eslint-disable-next-line
  }, [dispatch])

  window.addEventListener('resize', () => {
    setIsMobile(window.innerWidth <= 600);
  });

  if (isMobile) {
    return (
      <Grid container style={{ height: '100vh', backgroundColor: '#282c34' }} alignItems="center" justify="center">
        <Grid item xs={12} style={{ textAlign: 'center', padding: '20px', color: '#fff' }}>
          <Typography variant='h4' style={{ margin: 'auto', backgroundColor: '#3f51b5', borderRadius: '10px', padding: '20px' }}>
            This game is not available on mobile devices. Use landscape mode please
          </Typography>
        </Grid>
      </Grid>
    );
  }
  return (
    <Box>
      <Notifications />
      <Chat />
      <Routes>
        <Route path='/' element={<Board />}/>
        <Route path='/:gameId' element={<Board /> }/>
      </Routes>
    </Box>
  );
}

export default App;
