import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogTitle, Hidden, MenuItem, Tab, Tabs, TextField, Typography } from "@mui/material"
import PropTypes from "prop-types";
import { Box } from "@mui/system";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';

const PVForms = ({fields, record, onChangeRecord, onChange, title,
                    saveLabel, onSaveClicked, cancelLabel, onCancelClicked}) => {

    const recordChanged = (field, property, value) => {
        if(field.type === 'RICHTEXT' && value === '<p><br></p>'){
            return;
        }
        if(typeof(onChangeRecord) === 'function'){
            let changedRecord = {...record.editRecord, [property]: value }
            onChangeRecord({...record, editRecord: changedRecord});
        }
        if(typeof(field.onChange) === 'function'){
            onChange(value);
        }
    }

    const closeForm = () => {
        if(typeof(onChangeRecord) === 'function'){
            onChangeRecord({});
        }
    }

    const isValid = (fields, record) => {
        // Check if all the required fields are filled
        let requiredFields = fields.filter(field => field.required === true && (record?.[field.property] === undefined || record?.[field.property] === '')).length;
        if(requiredFields > 0){
            return false;
        }

        // Check if all the errors are cleared
        let errorFields = fields.filter(field => field.error === true).length;
        if(errorFields > 0){
            return false;
        }

        // Check if there is min length requirements
        let minRequired = fields.filter(field => field.minLength !== undefined)
                            .filter(field => record?.[field.property]?.length < field.minLength).length;
        if(minRequired > 0) {
            return false;
        }

        // The form is valid if it passes all the other validation checks
        return true;
    }

    

    return(
        <div>
            <Dialog open={record.open} fullWidth>
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <Hidden xlDown={fields?.length === 0}>
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            {fields.filter(field => field.visible !== false)?.map((field, i) => {
                                switch(field.type){
                                    case 'TEXTAREA':
                                        return(
                                            <TextField 
                                                label={field.label}
                                                variant="outlined"
                                                value={record.editRecord?.[field.property]}
                                                key={field.property}
                                                onChange={(e) => recordChanged(field, field.property, e.target.value)}
                                                disabled={field.disabled}
                                                multiline
                                                rows={field.rows || 1}
                                                style={{margin: 5}}
                                                error={field.error}
                                                required={field.required}
                                            />
                                        )
                                    case 'SELECT':
                                        return(
                                            <TextField
                                                variant="outlined"
                                                size="small"
                                                select
                                                value={record.editRecord?.[field.property]}
                                                key={field.property}
                                                label={field.label}
                                                style={{margin: 5}}
                                                disabled={field.disabled}
                                                onChange={(e) => recordChanged(field, field.property, e.target.value)}
                                                required={field.required}
                                            >
                                                {field?.listValues?.map((val, i) => (
                                                    <MenuItem value={val[field.listValueKey]}>{val[field.listLabelKey] || 'No Label'}</MenuItem>
                                                ))}
                                            </TextField>
                                        )
                                    case 'AUTOCOMPLETE':
                                        return(
                                            <Autocomplete 
                                                size="small"
                                                freeSolo
                                                key={field.property}
                                                style={{margin: 5}}
                                                disabled={field.disabled}
                                                onChange={(e, n) => recordChanged(field, field.property, n?.[field.listValueKey] || '')}
                                                required={field.required}
                                                options={field?.listValues}
                                                getOptionLabel={(option)=> option[field.listLabelKey] || 'No Label'}
                                                renderInput={(params) => <TextField
                                                    value={field?.listValues.filter(item => item[field.listValueKey] === record.editRecord?.[field.property])?.[0]?.[field.listLabelKey]}
                                                    {...params} label={field.label} />}
                                            />
                                        )
                                    case 'RICHTEXT':
                                        return(
                                            <Box style={{margin: 5, paddingBottom: 20}}>
                                                <Typography variant="subtitle1">{field.label}</Typography>
                                                <ReactQuill 
                                                    theme="snow"
                                                    value={record.editRecord?.[field.property]}
                                                    onChange={(value) => recordChanged(field, field.property, value)}
                                                    readOnly={field.disabled}
                                                    style={{ height: field.rows * 20 }}
                                                />
                                            </Box>
                                        )
                                    case 'TABS':
                                        return(
                                            <Box style={{margin: 5}}>
                                                <Tabs value={record.editRecord?.[field.property]} onChange={(e, n) => recordChanged(field, field.property, n)} centered>
                                                    {field?.listValues?.map((tab, i) => (
                                                        <Tab key={i} label={tab.label} value={tab.value} />
                                                    ))}
                                                </Tabs>
                                            </Box>
                                        )
                                    case 'CUSTOM':
                                        return(
                                            <Box style={{margin: 5}}>
                                                {field.reactComponent}
                                            </Box>
                                        )
                                    case 'TEXT':
                                        default:
                                        return(
                                            <TextField 
                                                label={field.label}
                                                variant="outlined"
                                                value={record.editRecord?.[field.property]}
                                                key={field.property}
                                                onChange={(e) => recordChanged(field, field.property, e.target.value)}
                                                disabled={field.disabled}
                                                style={{margin: 5}}
                                                error={field.error}
                                                required={field.required}
                                                type={field.dataType || "text"}
                                            />
                                        )
                                }   
                            })}
                        </div>
                    </Hidden>
                </DialogContent>
                <DialogActions>
                    <Button 
                        onClick={(e) => {
                            if(typeof(onSaveClicked) === 'function'){
                                onSaveClicked(record)
                            }
                        }}
                        disabled={!isValid(fields, record.editRecord)}
                    >{saveLabel || 'Save'}</Button>
                    <Button 
                        onClick={(e) => {
                            if(typeof(onCancelClicked) === 'function'){
                                onCancelClicked(e)
                            } else {
                                closeForm();
                            }
                        }}
                    >{cancelLabel || 'Cancel'}</Button>
                </DialogActions>
            </Dialog>
        </div>
    )

}

PVForms.propTypes = {
    /**
     * @param fields
     * 
     * Array of field details.
     * @description
     * Label: Will be shown as the hint
     * property: What the value will be stored as
     * disabled: defaults to false
     * error: defaults to false, will turn the field red
     * required: defaults to false, will check for form validation
     * listValues: required for only select type fields
     * listLabelKey: key for the label
     * listValueKey: key for the value that needs to be saved.
     * visible: defaults to false, to show/hide in the form
     * type: defaults to TEXT, type of the field
     */
    fields: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string.isRequired,
        property: PropTypes.string.isRequired,
        disabled: PropTypes.bool,
        rows: PropTypes.number,
        error: PropTypes.bool,
        required: PropTypes.bool,
        listValues: PropTypes.array,
        listLabelKey: PropTypes.string,
        listValueKey: PropTypes.string,
        visible: PropTypes.bool,
        dataType: PropTypes.string,
        type: PropTypes.oneOf(['TEXTAREA', 'SELECT', 'AUTOCOMPLETE', 'TEXT']),
        reactComponent: PropTypes.element
    })).isRequired,
    /**
     * @param record
     * 
     * Data of all the fields saved in one variable
     * 
     * @example
     * ```
     * record = {
     *  open: true,
     *  editRecord: {
     *    property: 'valueHere'
     *  }
     * }
     * ```
     */
    record: PropTypes.object.isRequired,
    /**
     * @param onChangeRecord
     * 
     * Function that will be called whenever there
     * is a change in the form fields.
     */
    onChangeRecord: PropTypes.func.isRequired,
    /**
     * @param onChange
     * 
     * Function that can be used to get callbacks for
     * the data being entered.
     */
    onChange: PropTypes.func,
    /**
     * @param title
     * 
     * String that will be shown at the top of the dialog.
     */
    title: PropTypes.string.isRequired,
    /**
     * @param saveLabel
     * @default "Save"
     * 
     * Text for the positive button
     */
    saveLabel: PropTypes.string,
    /**
     * @param onSaveClicked
     * 
     * Function that will be called with the formdetails
     */
    onSaveClicked: PropTypes.func.isRequired,
    /**
     * @param cancelLabel
     * @default "Cancel"
     * 
     * Label that will be used for the cancel button
     */
    cancelLabel: PropTypes.string,
    /**
     * @param onCancelClicked
     * @default 
     * ```
     * onChangeRecord({open: false})
     * ```
     * 
     * Function that will be called when cancel is clicked
     */
    onCancelClicked: PropTypes.func
}

export default PVForms;