import { createSlice } from "@reduxjs/toolkit";
import { HTTPRequest } from "../Api";

const PeerConnectionsSlice = createSlice({
    name: "peerConnections",
    initialState: {
        peerConnections: []
    },
    reducers: {
        setPeerConnections: (state, action) => {
            state.peerConnections = action.payload;
        }
    }
});

export function createCall(gameId) {
    return (dispatch) => {
        dispatch(HTTPRequest('POST', '/peerConnection/createcall', { gameId: gameId }, (data) => {
            dispatch(setPeerConnections(data));
        }))
    }
}

export function addFromConnector(connectorId, connector) {
    return (dispatch) => {
        dispatch(HTTPRequest('POST', `/peerConnection/addfromconnector/${connectorId}`, { fromConnectionDet: connector }, (data) => {
            dispatch(setPeerConnections(data));
        }))
    }
}

export function addToConnector(connectorId, connector) {
    return (dispatch) => {
        dispatch(HTTPRequest('POST', `/peerConnection/addtoconnector/${connectorId}`, { toConnectionDet: connector }, (data) => {
            dispatch(setPeerConnections(data));
        }))
    }
}

export function destroyConnection(connectorId) {
    return (dispatch) => {
        dispatch(HTTPRequest('POST', `/peerConnection/destroyandrecreate/${connectorId}`, {}, (data) => {
            dispatch(setPeerConnections(data));
        }))
    }
}

export const { setPeerConnections } = PeerConnectionsSlice.actions;

export const peerConnectionsSelector = (state) => state.peerConnections.peerConnections;

export default PeerConnectionsSlice.reducer;