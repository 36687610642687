import { AccountCircle, ChatBubble, Close, Send } from '@mui/icons-material';
import { AppBar, Badge, Box, Fab, IconButton, Paper, TextField, Toolbar, Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { badgeCountSelector, currentUserSelector, gameSelector, messagesSelector, sendMessage, setLastSeenMessageTime } from '../../services/GameSlice';
import './index.css';

const Chat = () => {
    const [isOpen, setIsOpen] = useState(false);
    const theme = useTheme();
    const dispatch = useDispatch();

    const [input, setInput] = useState('');

    const messages = useSelector(messagesSelector);
    const userId = useSelector(currentUserSelector);
    const game = useSelector(gameSelector);
    const badge = useSelector(badgeCountSelector);
    const messagesEndRef = React.useRef(null);

    const toggleChat = () => {
        setIsOpen(!isOpen);
    };

    const handleSubmit = (event) => {
        dispatch(sendMessage({message: input, senderId: userId, gameId: game._id}, () => {
            setInput('');
        }));
    }

    useEffect(() => {
        if (messagesEndRef.current) {
          const { scrollHeight } = messagesEndRef.current;
          messagesEndRef.current.scrollTop = scrollHeight;
        }
        if(isOpen){
            dispatch(setLastSeenMessageTime(new Date().getTime()));
        }
      }, [messages, isOpen, dispatch]);

    return (
        <div style={{zIndex: 10, position: 'absolute'}}>
            <Fab 
              style={{
                backgroundColor: badge === 0 ? theme.palette.primary.main : 'red', 
                color: '#FFF', 
                position: 'fixed',
                bottom: theme.spacing(2),
                right: theme.spacing(2),
                animation: badge !== 0 && 'pulse 1.5s infinite',
                borderRadius: '50%' // make the icon round
              }} 
              onClick={toggleChat}
            >
                <Badge badgeContent={badge} classes={{badge: {fontSize: '20px'}}}>
                    <ChatBubble />
                </Badge>
            </Fab>
            {isOpen && (
                <Paper style={{
                    position: 'fixed',
                    height: '60vh',
                    width: '300px',
                    bottom: theme.spacing(10),
                    right: theme.spacing(2),
                    overflow: 'auto',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                }}>
                    <AppBar position="static" color="primary" >
                        <Toolbar>
                            <Box flexGrow={1}>
                                <Typography variant="body1">Game Chat(Beta)</Typography>
                                {/* <Typography variant="subtitle2">This is a subtitle</Typography> */}
                            </Box>
                            <IconButton edge="end" color="inherit" onClick={toggleChat} aria-label="close">
                                <Close />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    <div ref={messagesEndRef} style={{ 
                      height: '80vh', 
                      overflowY: 'auto', 
                      padding: '10px', 
                      marginBottom: '2px', // space for the send message box
                      display: 'flex',
                      flexDirection: 'column',
                    }}>
                      {messages.map((m) => (
                        <div style={m.senderId === userId ? {display: 'flex', flexDirection: 'row'} : {}}>
                          {m.senderId === userId && <AccountCircle fontSize='small' style={{ marginBottom: 0, marginTop: 'auto', color: '#EF9CA1' }}/>}
                          <div style={m.senderId !== userId ? {
                                                                    fontSize: 14,
                                                                    backgroundColor: '#f8f8f9', 
                                                                    borderRadius: '10px 10px 0 10px', 
                                                                    float: 'right', 
                                                                    maxWidth: '80%', 
                                                                    padding: 10, 
                                                                    marginTop: 2, 
                                                                    marginRight: 10
                                                                  } : {
                                                                    fontSize: 14,
                                                                    color: '#09122b', 
                                                                    border: '1px solid #d6d7db', 
                                                                    borderRadius: '10px 10px 10px 0', 
                                                                    float: 'left',
                                                                    padding: 10, 
                                                                    marginTop: 2, 
                                                                    marginLeft: 4, 
                                                                    maxWidth: '80%'
                                                                  }}>
                            <div>
                              {m.message}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    padding: theme.spacing(1),
                                }}>
                        <TextField
                            value={input}
                            onChange={(event) => setInput(event.target.value)}
                            placeholder="Type your message..."
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            multiline
                            rows={2}
                            style={{
                                flex: 1,
                                marginRight: theme.spacing(1),
                            }}
                            onKeyDownCapture={(event) => {
                                if(event.key === 'Enter' && !event.shiftKey){
                                    event.preventDefault();
                                    handleSubmit(event);
                                }
                            }}
                        />
                        <IconButton style={{backgroundColor: theme.palette.primary.main, color: '#FFF'}} onClick={(event) => {
                          handleSubmit(event);
                        }} disabled={!input}>
                            <Send fontSize='small' />
                        </IconButton>
                    </div>
                </Paper>
            )}
        </div>
    );
};

export default Chat;