import { combineReducers } from "@reduxjs/toolkit";
import GameSlice from "../services/GameSlice";
import NotificationSlice from "../services/NotificationSlice";
import PeerConnectionsSlice from "../services/PeerConnectionsSlice";

const appReducer = combineReducers({
    game: GameSlice,
    notificationHandler: NotificationSlice,
    peerConnections: PeerConnectionsSlice
})

const rootReducer = (state, actions) => {
    return appReducer(state, actions)
}

export default rootReducer;