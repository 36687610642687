import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Tooltip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PVForms from "../../Custom/PVForms";
import { useDispatch, useSelector } from "react-redux";
import { analyticsInstanceSelector, checkAndJoinGame, createGame, gameSelector, getGame, getMessages, joinGame, makeMove, sendFeedback, setCurrentUser, setPlayers, startGame, trackGame } from "../../services/GameSlice";
import { nanoid } from "@reduxjs/toolkit";
import PVTable from "../../Custom/PVTable";
import copy from "copy-to-clipboard";
import { setBody, setType } from "../../services/NotificationSlice";
import ColorPicker from "react-pick-color";
import { addFromConnector, addToConnector, createCall, destroyConnection, peerConnectionsSelector } from "../../services/PeerConnectionsSlice";
import * as Peer from 'simple-peer';
import { setUserId as analyticsUserId, logEvent } from "firebase/analytics";

const Board = () => {

    const params = useParams();

    const [record, setRecord] = useState({});
    const [nameRecord, setNameRecord] = useState({});
    const [userId, setUserId] = useState('');
    const [selectedCard, setSelectedCard] = useState(null);
    const [feedbackForm, setFeedbackForm] = useState({});
    const [color, setColor] = useState('#03b1fc');
    const [opneColorPicker, setOpenColorPicker] = useState(false);
    const [mediaStream, setMediaStream] = useState(null);
    const [mediaPermission, setMediaPermission] = useState(false);
    const [connections, setConnections] = useState([]);
    const analytics = useSelector(analyticsInstanceSelector);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const game = useSelector(gameSelector);
    const peerConnections = useSelector(peerConnectionsSelector);

    const data = {
        '0,0': 'blank_card',
        '0,1': 'spades_10',
        '0,2': 'spades_queen',
        '0,3': 'spades_king',
        '0,4': 'spades_ace_simple',
        '0,5': 'diamonds_2',
        '0,6': 'diamonds_3',
        '0,7': 'diamonds_4',
        '0,8': 'diamonds_5',
        '0,9': 'blank_card',

        '1,0': 'spades_9',
        '1,1': 'hearts_10',
        '1,2': 'hearts_9',
        '1,3': 'hearts_8',
        '1,4': 'hearts_7',
        '1,5': 'hearts_6',
        '1,6': 'hearts_5',
        '1,7': 'hearts_4',
        '1,8': 'hearts_3',
        '1,9': 'diamonds_6',

        '2,0': 'spades_8',
        '2,1': 'hearts_queen',
        '2,2': 'diamonds_7',
        '2,3': 'diamonds_8',
        '2,4': 'diamonds_9',
        '2,5': 'diamonds_10',
        '2,6': 'diamonds_queen',
        '2,7': 'diamonds_king',
        '2,8': 'hearts_2',
        '2,9': 'diamonds_7',

        '3,0': 'spades_7',
        '3,1': 'hearts_king',
        '3,2': 'diamonds_6',
        '3,3': 'clubs_2',
        '3,4': 'hearts_ace',
        '3,5': 'hearts_king',
        '3,6': 'hearts_queen',
        '3,7': 'diamonds_ace',
        '3,8': 'spades_2',
        '3,9': 'diamonds_8',

        '4,0': 'spades_6',
        '4,1': 'hearts_ace',
        '4,2': 'diamonds_5',
        '4,3': 'clubs_3',
        '4,4': 'hearts_4',
        '4,5': 'hearts_3',
        '4,6': 'hearts_10',
        '4,7': 'clubs_ace',
        '4,8': 'spades_3',
        '4,9': 'diamonds_9',

        '5,0': 'spades_5', 
        '5,1': 'clubs_2',
        '5,2': 'diamonds_4',
        '5,3': 'clubs_4',
        '5,4': 'hearts_5',
        '5,5': 'hearts_2',
        '5,6': 'hearts_9',
        '5,7': 'clubs_king',
        '5,8': 'spades_4',
        '5,9': 'diamonds_10',

        '6,0': 'spades_4',
        '6,1': 'clubs_3',
        '6,2': 'diamonds_3',
        '6,3': 'clubs_5',
        '6,4': 'hearts_6',
        '6,5': 'hearts_7',
        '6,6': 'hearts_8',
        '6,7': 'clubs_queen',
        '6,8': 'spades_5',
        '6,9': 'diamonds_queen',

        '7,0': 'spades_3',
        '7,1': 'clubs_4',
        '7,2': 'diamonds_2',
        '7,3': 'clubs_6',
        '7,4': 'clubs_7',
        '7,5': 'clubs_8',
        '7,6': 'clubs_9',
        '7,7': 'clubs_10',
        '7,8': 'spades_6',
        '7,9': 'diamonds_king',

        '8,0': 'spades_2',
        '8,1': 'clubs_5',
        '8,2': 'spades_ace_simple',
        '8,3': 'spades_king',
        '8,4': 'spades_queen',
        '8,5': 'spades_10',
        '8,6': 'spades_9',
        '8,7': 'spades_8',
        '8,8': 'spades_7',
        '8,9': 'diamonds_ace',

        '9,0': 'blank_card',
        '9,1': 'clubs_6',
        '9,2': 'clubs_7',
        '9,3': 'clubs_8',
        '9,4': 'clubs_9',
        '9,5': 'clubs_10',
        '9,6': 'clubs_queen',
        '9,7': 'clubs_king',
        '9,8': 'clubs_ace',
        '9,9': 'blank_card'
    }

    useEffect(() => {
        if(Boolean(analytics) && Boolean(userId)){
            analyticsUserId(analytics, userId);
            logEvent(analytics, 'game_intialized', { userId })
        }
    }, [analytics, userId])

    useEffect(() => {
        let userId = localStorage.getItem("userId");
        setUserId(userId);
        if(!Boolean(userId)){
            userId = nanoid();
            localStorage.setItem("userId", userId);
            setUserId(userId);
        }
        dispatch(setCurrentUser(userId));
        if(!Boolean(params.gameId)){
            setRecord({open: true, editRecord: {tabValue: 0}});
        } else if(nameRecord?.open !== true){
            dispatch(getGame(params.gameId, userId, (game) => {
                setInterval(() => {
                    dispatch(getMessages(params.gameId));
                }, 1000);
                if(game?.players){
                    if(game.players.filter(item => item.userId === userId).length === 0 && nameRecord?.open !== true && (game?.status === 'INIT' || game?.status === 'WAITING')){
                        setNameRecord({open: true, editRecord: {userId: userId}});
                    } else if(game.players.filter(item => item.userId === userId).length === 1 && (game?.status === 'INIT' || game?.status === 'WAITING')){
                        setInterval(() => {
                            dispatch(getGame(params.gameId, userId));
                        }, 1000);
                    } else if(game.status === 'START'){
                        setInterval(() => {
                            dispatch(getGame(params.gameId, userId));
                        }, 1000);
                    }
                        
                }
            }));
        }
    }, [params, dispatch, nameRecord])

    useEffect(() => {
        if(Array.isArray(peerConnections) && peerConnections.length > 0){
            const initiator = peerConnections?.filter(item => item.fromUserId === userId);
            const receiver = peerConnections?.filter(item => item.toUserId === userId);
            let dataConnections = [];
            initiator?.forEach(item => {
                if(!connections.find(i => i.id === item._id)){
                    var peer = new Peer({
                        initiator: true,
                        trickle: false,
                        stream: mediaStream,
                        offerOptions: {
                            offerToReceiveAudio: true,
                            offerToReceiveVideo: true
                        },
                        channelName: game.gameId,
                    })
                    peer.on('signal', (data) => {
                        dispatch(addFromConnector(item._id, data));
                    })
                    peer.on('stream', (stream) => {
                        var video = document.getElementById(item.toUserId);
                        if('srcObject' in video){
                            video.srcObject = stream;
                        } else {
                            video.src = window.URL.createObjectURL(stream);
                        }
                        video.play();
                    });
                    peer.on('error', (err) => {
                        dispatch(destroyConnection(item._id));
                        peer.destroy();
                    })
                    dataConnections.push({
                        id: item._id,
                        type: 'initiator',
                        peer: peer,
                        waitingToDet: true
                    });
                } else {
                    dataConnections.push(connections.find(i => i.id === item._id));
                }
            })
            receiver?.forEach(item => {
                if(!connections.find(i => i.id === item._id)){
                    var peer = new Peer({
                        initiator: false,
                        trickle: false,
                        stream: mediaStream,
                        offerOptions: {
                            offerToReceiveAudio: true,
                            offerToReceiveVideo: true
                        },
                        channelName: game.gameId,
                    });
                    peer.on('signal', (data) => {
                        dispatch(addToConnector(item._id, data));
                    })
                    peer.on('stream', (stream) => {
                        var video = document.getElementById(item.fromUserId);
                        if('srcObject' in video){
                            video.srcObject = stream;
                        } else {
                            video.src = window.URL.createObjectURL(stream);
                        }
                        video.play();
                    });
                    peer.on('error', (err) => {
                        dispatch(destroyConnection(item._id));
                        peer.destroy();
                    })
                    dataConnections.push({
                        id: item._id,
                        type: 'receiver',
                        peer: peer,
                        waitingFromDet: true
                    });
                } else {
                    dataConnections.push(connections.find(i => i.id === item._id));
                }
            })
            dataConnections.forEach(item => {
                if(item.type === 'initiator' && item.waitingToDet && peerConnections.find(i => i._id === item.id)?.toConnectionDet){
                    item.peer.signal(peerConnections.find(i => i._id === item.id)?.toConnectionDet);
                    item.waitingToDet = false;
                } else if(item.type === 'receiver' && item.waitingFromDet && peerConnections.find(i => i._id === item.id)?.fromConnectionDet){
                    item.peer.signal(peerConnections.find(i => i._id === item.id)?.fromConnectionDet);
                    item.waitingFromDet = false;
                }
            });
            setConnections(dataConnections);
            console.log(dataConnections);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [peerConnections, dispatch, userId, game])

    const create = () => {
        dispatch(createGame(record.editRecord, (game) => {
            setRecord({});
            navigate(`/${game._id}`)
            logEvent(analytics, 'game_created', {
                userId, gameId: game._id
            })
        }));
    }

    const checkAndJoin = () => {
        dispatch(checkAndJoinGame({name: record.editRecord.name, userId: userId}, (game) => {
            setRecord({});
            navigate(`/${game._id}`);
        }))
        dispatch(trackGame(params.gameId, userId));
    }

    const join = () => {
        dispatch(joinGame(params.gameId, nameRecord.editRecord, () => setNameRecord({})));
        dispatch(trackGame(params.gameId, userId));
        logEvent(analytics, 'game_joined', {
            userId, gameId: params.gameId
        })
    }

    const start = () => {
        dispatch(startGame(params.gameId));
        logEvent(analytics, 'game_started', {
            userId, gameId: params.gameId
        })
    }

    const canStartGame = () => {
        if(game?.players?.length === game?.noOfPlayers
            && game?.players?.filter(item => item.team === undefined).length === 0){
            let count = {};
            game.players.forEach(item => {
                if(count[item.team]){
                    count[item.team] += 1;
                } else {
                    count[item.team] = 1;
                }
            })
            let values = Object.values(count);
            if(values.every(val => val === values[0])){
                return true;
            }     
        }
        return false;
    }

    const move = (pos) => {
        if(((!Boolean(game?.moves?.find(m => m.pos === pos)) && selectedCard === data[pos]) ||
            (['spades_jack', 'hearts_jack'].includes(selectedCard) && game?.moves?.find(m => m.pos === pos && m.team !== game?.players?.find(p => p.userId === userId)?.team?.toLowerCase())) ||
            (['clubs_jack', 'diamonds_jack'].includes(selectedCard) && !Boolean(game?.moves?.find(m => m.pos === pos)))) && game.currentPlayer === userId &&
            ![`0,0`, `0,9`, `9,0`, `9,9`].includes(pos)){
                dispatch(makeMove(params.gameId, pos, userId, selectedCard, game));
        }
    }

    const onRowClick = (data, key) => {
        let players;
        switch (key){
            case 'assignGreen':
                players = game.players.map(item => {
                    if(item.userId === data.userId){
                        return {...item, team: 'Green'}
                    }
                    return item;
                })
                dispatch(setPlayers(game._id, players));
                break;
            case 'assignBlue': 
                players = game.players.map(item => {
                    if(item.userId === data.userId){
                        return {...item, team: 'Blue'}
                    }
                    return item;
                })
                dispatch(setPlayers(game._id, players));
                break;
            case 'assignRed':
                players = game.players.map(item => {
                    if(item.userId === data.userId){
                        return {...item, team: 'Red'}
                    }
                    return item;
                })
                dispatch(setPlayers(game._id, players));
                break;
            default:
                console.log("Nothing to execute");
        }
    }

    const cursor = (pos) => {
        if(((!Boolean(game?.moves?.find(m => m.pos === pos)) && selectedCard === data[pos]) ||
            (['spades_jack', 'hearts_jack'].includes(selectedCard) && game?.moves?.find(m => m.pos === pos && m.team !== game?.players?.find(p => p.userId === userId)?.team?.toLowerCase())) ||
            (['clubs_jack', 'diamonds_jack'].includes(selectedCard) && !Boolean(game?.moves?.find(m => m.pos === pos)))) && game.currentPlayer === userId &&
            ![`0,0`, `0,9`, `9,0`, `9,9`].includes(pos)){
            return 'pointer';
        }
        return 'not-allowed';
    }

    const getWon = () => {
        const noOfTeams = game?.noOfTeams;
        if(noOfTeams === 2){
            if(game.completedSequences.red === 2){
                let players = game.players.filter(i => i.team === 'Red').map(item => item.name);
                return `Red Team(${players.join(', ')}) Won`;
            } else if(game.completedSequences.blue === 2){
                let players = game.players.filter(i => i.team === 'Blue').map(item => item.name);
                return `Blue Team(${players.join(', ')}) Won`;
            } else if(game.completedSequences.green === 2){
                let players = game.players.filter(i => i.team === 'Green').map(item => item.name);
                return `Green Team(${players.join(', ')}) Won`;
            }
        } else if(noOfTeams === 3){
            if(game.completedSequences.red === 1){
                let players = game.players.filter(i => i.team === 'Red').map(item => item.name);
                return `Red Team(${players.join(', ')}) Won`;
            } else if(game.completedSequences.blue === 1){
                let players = game.players.filter(i => i.team === 'Blue').map(item => item.name);
                return `Blue Team(${players.join(', ')}) Won`;
            } else if(game.completedSequences.green === 1){
                let players = game.players.filter(i => i.team === 'Green').map(item => item.name);
                return `Green Team(${players.join(', ')}) Won`;
            }
        }
    }

    const getMediaPermission = () => {
        navigator.mediaDevices.getUserMedia({video: true, audio: true}).then((stream) => {
            setMediaStream(stream);
            setMediaPermission(true);
            checkAndCreateConnections();
        }).catch((err) => {
            console.log(err);
        })
    }

    const checkAndCreateConnections = () => {
        console.log(mediaPermission, Boolean(userId));
        if(Boolean(userId)){
            setInterval(() => {
                dispatch(createCall(game._id));
            }, 3000);
        }
    }

    const showTime = (userId) => {
        if(!Boolean(userId)){
            return '';
        }
        if (Boolean(game?.lastUpdated?.[userId]) && (game?.lastUpdated?.[userId] < new Date().getTime() - 3 * 1000)) {
            return `(Last seen ${Math.round((new Date().getTime() - new Date(game?.lastUpdated?.[userId]).getTime()) / 1000)} seconds ago)`;
        } else {
            return '';
        }
    }

    return (
        <Grid style={{backgroundColor: color, width: '100vw', minHeight: '100vh'}}>
            <Grid container style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                <Grid item>
                </Grid>
                <Grid item style={{display: 'flex', justifyContent: 'center'}}>
                    <Typography variant="h4">{game.status === 'COMPLETED'? getWon() : game.currentPlayer === userId ? 'Your Turn' : 'Not your turn'}</Typography>
                </Grid>
                <Grid item>
                    <Grid style={{display: 'flex', flexDirection: 'row'}}>
                        <Button variant="contained" style={{marginRight: 20, marginTop: 10}} onClick={() => {
                            setOpenColorPicker(true);
                        }}>Change Background Color</Button>
                        {game.callEnabled === true && <Button variant="contained" style={{marginRight: 20, marginTop: 10}} onClick={() => {
                            getMediaPermission(true);
                        }}>Start Call</Button>}
                        <Button variant="contained" style={{marginRight: 20, marginTop: 10}} onClick={() => {
                            setFeedbackForm({open: true});
                        }}>Feedback</Button>
                        <Button variant="contained" style={{marginRight: 20, marginTop: 10}} onClick={() => {
                            navigate('/');
                            window.location.reload();
                        }}>New Game</Button>
                    </Grid>
                </Grid>
            </Grid>
            <Dialog open={opneColorPicker}>
                <DialogTitle>Pick a Background Color</DialogTitle>
                <DialogContent>
                    <ColorPicker color={color} onChange={(c) => setColor(c.hex) }/>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setColor('#03b1fc')}>Reset</Button>
                    <Button onClick={() => setOpenColorPicker(false)}>Save</Button>
                </DialogActions>
            </Dialog>
            <PVForms 
                fields={[
                    {
                        label: 'Report Type',
                        property: 'type',
                        required: true,
                        type: 'SELECT',
                        listValues: [
                            {
                                label: 'Bug',
                                value: 'bug'
                            },
                            {
                                label: 'Feature Request',
                                value: 'feature'
                            },
                            {
                                label: 'Other',
                                value: 'other'
                            }
                        ],
                        listLabelKey: 'label',
                        listValueKey: 'value'
                    },
                    {
                        label: 'Description',
                        property: 'description',
                        required: true,
                        type: 'TEXTAREA',
                        rows: 5
                    }
                ]}
                record={feedbackForm}
                onChangeRecord={setFeedbackForm}
                title="Provide Feedback"
                saveLabel={'Submit'}
                onSaveClicked={() => {
                    dispatch(sendFeedback(feedbackForm.editRecord, () => {
                        setFeedbackForm({});
                    }))
                }}
            />
            <Dialog open={(game?.status === 'INIT' || game?.status === 'WAITING') && game?.players?.length > 0}>
                <DialogTitle>Team</DialogTitle>
                <DialogContent>
                    {game?.status === 'INIT' && <Typography variant="body1">Copy the link below and share it with your friends to start playing</Typography>}
                    {game?.status === 'WAITING' && <Typography variant="body1">Waiting for someone to join</Typography>}
                    {game?.status === 'INIT' && <Tooltip title="Click to Copy">
                        <span style={{cursor: 'pointer', textDecoration: 'underline'}} onClick={() => {
                            copy(window.location.href)
                            dispatch(setBody('Copied successully'));
                            dispatch(setType('success'))
                        }}>{window.location.href}</span> 
                    </Tooltip>}
                    <PVTable 
                        columns={[
                            {
                                label: 'Name',
                                property: 'name'
                            },
                            {
                                label: 'Team',
                                property: 'team'
                            }
                        ]}
                        data={game.players}
                        clickMenu={[
                            {
                                key: 'assignGreen',
                                label: 'Assign Green',
                                enabled: (game?.noOfPlayers % 2 === 0 || game?.noOfPlayers % 3 === 0) && game?.players?.filter(item => item.team === 'Green').length < Math.floor(game?.noOfPlayers / 2)
                            },
                            {
                                key: 'assignBlue',
                                label: 'Assign Blue',
                                enabled: (game?.noOfPlayers % 2 === 0 || game?.noOfPlayers % 3 === 0) && game?.players?.filter(item => item.team === 'Blue').length < Math.floor(game?.noOfPlayers / 2)
                            },
                            {
                                key: 'assignRed',
                                label: 'Assign Red',
                                enabled: game?.noOfPlayers % 3 === 0
                            }
                        ]}
                        onRowClick={onRowClick}
                    />
                </DialogContent>
                <DialogActions style={{textAlign: 'center'}}>
                    <Button onClick={start} disabled={!canStartGame()}>Start Game</Button>
                </DialogActions>
            </Dialog>

            <PVForms 
                fields={[
                    {
                        label: 'New Game',
                        property: 'tabValue',
                        listValues: [
                            {
                                label: 'Create New Game',
                                value: 0
                            },
                            {
                                label: 'Join Web Game (Beta)',
                                value: 1
                            }
                        ],
                        type: 'TABS'
                    },
                    {
                        type: 'CUSTOM',
                        reactComponent: <Typography variant="caption" style={{textAlign: 'center'}}>Use this to play with your friends (You will get a sharable link in the next step)</Typography>,
                        visible: record?.editRecord?.tabValue === 0
                    },
                    {
                        type: 'CUSTOM',
                        reactComponent: <Typography variant="caption" style={{textAlign: 'center'}}>Use this to join a game with a random person (Beta)</Typography>,
                        visible: record?.editRecord?.tabValue === 1
                    },
                    {
                        label: 'Room Name',
                        property: 'roomName',
                        required: record?.editRecord?.tabValue === 0,
                        visible: record?.editRecord?.tabValue === 0
                    },
                    {
                        label: 'Number of Players',
                        property: 'noOfPlayers',
                        required: record?.editRecord?.tabValue === 0,
                        dataType: 'number',
                        error: Boolean(record?.editRecord?.noOfPlayers) && !(record?.editRecord?.noOfPlayers > 1 
                            && record?.editRecord?.noOfPlayers <= 12 
                            && (record?.editRecord?.noOfPlayers % 2 === 0 || record?.editRecord?.noOfPlayers % 3 === 0)),
                        visible: record?.editRecord?.tabValue === 0
                    },
                    {
                        label: 'Name',
                        property: 'name',
                        required: record?.editRecord?.tabValue === 1,
                        visible: record?.editRecord?.tabValue === 1
                    }
                ]}
                record={record}
                onChangeRecord={setRecord}
                // title="Create a Game"
                onSaveClicked={record?.editRecord?.tabValue === 0 ? create : checkAndJoin}
                saveLabel={record?.editRecord?.tabValue === 0 ? 'Create' : 'Join'}
            />
            <PVForms 
                fields={[
                    {
                        label: 'Name',
                        property: 'name',
                        required: true
                    }
                ]}
                record={nameRecord}
                onChangeRecord={setNameRecord}
                title="User Name"
                saveLabel={'Join Game'}
                onSaveClicked={join}
            />
            <Grid container>
                <Grid item xs={12} md={8} lg={8} style={{marginTop: '10vh', textAlign: 'center'}}>
                    <Typography variant="h3">Board</Typography>
                    {Array.from({ length: 10 }).map((lol, i) => (
                        <Grid container key={i}>
                            <Grid item xs={1} md={1} lg={1}/>
                            {Array.from({ length: 10 }).map((lol1, j) => (
                                <Grid item xs={1} md={1} lg={1} key={j} style={{ paddingLeft: 5, paddingRight: 5, textAlign: 'center', position: 'relative' }}>
                                <img
                                    src={`https://d1u9hkaasftir6.cloudfront.net/65777e4bf3e55914957c7b26/65777e4cf3e55914957c7b29/cardfronts/${Object.keys(data).includes(`${i},${j}`) ? data[`${i},${j}`] : 'spades_ace_simple'}.png`}
                                    alt={`At ${i} ${j}`}
                                    width={'100%'}
                                    onClick={() => move(`${i},${j}`)}
                                    style={{cursor: cursor(`${i},${j}`),
                                            border: Boolean(game?.moves?.find(m => m.pos === `${i},${j}`)?.completedSequence) ? '2px solid black' : 'none',}}
                                />
                                {game?.moves && Boolean(game.moves.find(m => m.pos === `${i},${j}`)) && <div style={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    width: 25,
                                    height: 25,
                                    backgroundColor: game.moves.find(m => m.pos === `${i},${j}`).team,
                                    animation: game.moves[game.moves.length - 1].pos === `${i},${j}` && 'blink 0.75s steps(5, start) 5',
                                    borderRadius: '50%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    cursor: cursor(`${i},${j}`)
                                }} onClick={() => move(`${i},${j}`)}/>}
                                </Grid>
                            ))}
                            <Grid item xs={1} md={1} lg={1}/>
                        </Grid>
                    ))}
                </Grid>
                <Grid item xs={12} md={4} lg={4} style={{marginTop: '10vh'}}>
                    <Typography variant="h3">{`Your Cards (${Boolean(game?.players?.find(p => p.userId === userId)?.team) ? game?.players?.find(p => p.userId === userId)?.team: ''})`}</Typography>
                    <Typography variant="body1">{`Current Player - ${Boolean(game?.players?.find(p => p.userId === game?.currentPlayer)?.name) ? game?.players?.find(p => p.userId === game?.currentPlayer)?.name : ''} ${showTime(game?.currentPlayer)}`}</Typography>
                    <Grid container style={{justifyContent: 'center', marginTop: '5vh'}}>
                        {game?.players?.filter(user => user.userId === userId)?.[0]?.inHandCards && game?.players?.filter(user => user.userId === userId)[0]?.inHandCards.map((_, i) => (
                            <Grid item xs={3} md={3} lg={3}>
                                <img
                                    src={`https://d1u9hkaasftir6.cloudfront.net/65777e4bf3e55914957c7b26/65777e4cf3e55914957c7b29/cardfronts/${game?.players?.filter(user => user.userId === userId)[0]?.inHandCards[i]}.png`}
                                    alt={`At ${i} ${i}`}
                                    width={'100%'}
                                    onClick={() => {
                                        if(game.currentPlayer === userId){
                                            setSelectedCard(game?.players?.filter(user => user.userId === userId)[0]?.inHandCards[i])
                                        }
                                    }}
                                    style={{cursor: game.currentPlayer === userId ? 'pointer' : 'not-allowed',
                                            transform: 'rotate(-90deg)'}}
                                />
                            </Grid>
                        ))}
                    </Grid>
                    {game.callEnabled === true && <Grid container style={{justifyContent: 'center', marginTop: '5vh'}}>
                        {game?.players?.filter(user => user.userId !== userId)?.map((user, i) => (
                            <Grid item xs={12} md={5} lg={5} style={{textAlign: 'center'}}>
                                <Typography variant="body1">{user.name}</Typography>
                                <video id={user.userId} style={{width: 150, height: 150}}/>
                            </Grid>
                        ))}
                    </Grid>}
                </Grid>
            </Grid>
        </Grid>
    )
}

export default Board;